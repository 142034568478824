<template>
    <section class="chats-residente">
        <div class="enviar-mensaje-nuevo" :class="show ? 'activo':'inactivo'">
            <div class="row header mx-0 py-2">
                <p class="col-12 f-17 f-500">Residentes ({{ usersChats.length }})</p>
                <div class="col-auto my-auto">
                    <i class="icon-arrow-left-circle f-25 my-auto cr-pointer" @click="$emit('close')" />
                </div>
                <div class="col my-auto">
                    <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" @input="buscarUser" />
                </div>
            </div>
            <div class="row header mx-0 py-2">
                <div class="col-6 my-auto">
                    <el-select v-model="idVivienda" filterable clearable placeholder="Agrupaciones" size="small" class="w-100" @change="getUserViviendas">
                        <el-option
                        v-for="item in viviendas"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-6 my-auto">
                    <el-select v-model="idViviendaHija" filterable clearable placeholder="Viviendas" size="small" class="w-100" @change="getUserViviendasHija">
                        <el-option
                        v-for="item in viviendasHijas"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <div class="contenedor-nuevo-mensaje overflow-auto scroll-none">
                        <div v-for="(user,key) in usersChats" :key="key" class="row mx-0 py-2 border-bottom cr-pointer" @click="iniciarChat(user)">
                            <div class="col-9 pl-2">
                                <div class="d-middle">
                                    <div :class="`tipo-user wh-32px d-middle-center mr-2`">
                                        <i :class="`${iconUser(user.tipo)} p-1`" />
                                    </div>
                                    <div class="tres-puntos">
                                        <p class="f-15 f-500"> {{ user.nombre }}</p>
                                        <p class="f-13 text-86 tres-puntos"> {{ user.vivienda }} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 text-right">
                                <p class="f-10 text-86"> {{ setFecha(user.fecha_ultimo_chat) }} </p>
                                <div v-if="user.cant_no_leidos > 0" class="f-10 f-500 text-white wh-20px rounded-circle d-middle-center ml-auto mt-1" style="background: #109881;">
                                    {{ user.cant_no_leidos }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import Viviendas from '~/services/viviendas/index'
import moment from 'moment'

export default {
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            search:'',
            usersChats:[],
            viviendas: [],
            idVivienda: null,
            vivienda: '',
            viviendasHijas: [],
            idViviendaHija: null,
            viviendaHija: ''
        }
    },
    mounted(){
        this.getViviendas();
    },
    methods:{
        async buscarUser(){
            try {
                if(this.search.length > 2 || this.vivienda.length > 0 || this.viviendaHija.length > 0){
                    const busqueda = async() => {
                        let params = { 
                            query : this.search,
                            grupo : this.vivienda,
                            vivienda : this.viviendaHija,
                        }
                        
                        const {data} = await Chats.buscarChatResidente(params)
                        this.usersChats = data.usuarios
                    }
                    await this.delay(busqueda)
                }else if(this.search.length === 0){
                    this.usersChats = []
                }
            } catch (e){
                this.errorCatch(e)
            }
        },
        iniciarChat(user){
            this.$store.dispatch('chats/getDataResidente',user)
            this.$store.dispatch('chats/getConversacion',user)
            this.$emit('close')
        },
        setFecha(fecha){
            if(!fecha) return ''
            if(moment(fecha).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')){
                return moment(fecha).format('DD/MM/YYYY')
            }
            return moment(fecha).format('HH:mm a')
        },
        async getViviendas(){
            try {
                const {data} = await Viviendas.getViviendas()
                this.viviendas = data.data
                this.getViviendasHijas();
            } catch (e){
                this.errorCatch(e)
            }
        },
        async getViviendasHijas(){
            try {
                let params = { 
                    id_padre : this.idVivienda,
                }
                const {data} = await Viviendas.getViviendasHijas(params)
                this.viviendasHijas = data.data
            } catch (e){
                this.errorCatch(e)
            }
        },
        getUserViviendas(id){
            if (id){
                let data = this.viviendas.find(o=>o.id === id);
                this.vivienda = data.nombre;
                this.viviendaHija = '';
                this.getViviendasHijas();
            }else{
                this.vivienda = '';
            }
            this.buscarUser();
        },
        getUserViviendasHija(id){
            if (id){
                let data = this.viviendasHijas.find(o=>o.id === id);
                this.viviendaHija = data.nombre;
            }else{
                this.viviendaHija = '';
            }
            this.buscarUser();
        }
    }
}
</script>

<style lang="scss" scoped>
.chats-residente{
    .wh-164px{
        max-width: 164px;
        min-width: 164px;
        max-height: 164px;
        min-height: 164px;
    }
    @keyframes slidein {
        from {
            width: 0px;
        }

        to {
            width: 395px;
        }
    }
    .enviar-mensaje-nuevo{
        background: #fff;
        height: calc(100vh - 56px);
        position: absolute;
        top: 0;
        width: 378px;
        left: -393.77px;
        display: none;
        &.activo{
            transform: translateX(395px);
            display: block !important;
            animation-duration: 0.35s;
            // animation-name: slidein;
            z-index: 2;
        }
        .header{
            background: #f1f1f1;
        }
        .contenedor-nuevo-mensaje{
            height: calc(100vh - 160px);
        }
       
    }
    
}
</style>